import React from 'react';
import Navigation from "./components/Navigation";
import About, {aboutText} from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Product from "./components/pages/Product";
import Agency from "./components/pages/Agency";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Container, Responsive } from 'semantic-ui-react';
import "./styles/app.css"
import { Helmet } from 'react-helmet';
import { WIDTH_PHONE } from './Variables';
import ResponsiveLayout, { DisplaySizeType } from "./components/ResponsiveLayout";

// import 'primereact/resources/themes/nova-light/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';






function App() {

	const renderContext =  (size: DisplaySizeType): JSX.Element => {
		const additionalProps: any = {};
		if ( size === "mobile") {
			additionalProps.className = "mt-24"
		}
		return  (
			<>
				<Navigation/>
				<Container {...additionalProps}>
					<Switch>
						<Route path="/" exact component={About} /> 
						<Route path="/about" component={About} /> 
						<Route path="/contact" component={Contact} /> 
						<Route path="/agency" component={Agency} /> 
						
						<Route path="/product" exact component={Product} /> 
						<Route path="/product/:productId" component={Product} /> 
					</Switch>
				</Container>
			</>
		)
	}


	const renderDesktop = (size : DisplaySizeType): JSX.Element => {
		if (size === "desktopSmall") {
			return renderContext("desktopSmall");
		} else {
			return renderContext("desktopLarge");
		}  
	}

	const renderMobile = ():JSX.Element => { 
		return renderContext("mobile");
	}


  return (
	<Router>
		<Helmet>
			<title>Denmyd Medical Equipment (Pty) Ltd.</title>
			<meta name="description" content={aboutText}/>
			<meta name="viewport" content="width=device-width, initial-scale=1"/>
		</Helmet>
		<ResponsiveLayout 
			desktop= { renderDesktop }
			mobile = { renderMobile }
		/>
	</Router>
  );
}

export default App;
