import React, { useContext } from "react"
import { Image, ImageGroup } from 'semantic-ui-react';
import { DisplaySizeContext } from "../ResponsiveLayout";

export interface IFeatureListProps {
	imgURL?: string,
	imgAlt?: string,
	imageSize?: {
		type: "fixed";
		width: string;
	} | {
		type: "device";
		mobile: string;
		desktopSmall: string;
		desktopLarge: string;
	}
	title: string | JSX.Element;
	meta? : string | JSX.Element
	content? : string | JSX.Element
}

function FeatureItem(props: IFeatureListProps) {

	const displaySize = useContext(DisplaySizeContext);

	const getImage = () => {
		if (props.imgURL) {
			const imagProps: any = {};
			if (props.imageSize){
				if (props.imageSize.type === "fixed") {
					imagProps.size = props.imageSize.width;
				} else {
					switch ( displaySize ) {
						case "mobile": {
							imagProps.size = props.imageSize.mobile;
						}
			
						case "desktopSmall": {
							imagProps.size = props.imageSize.desktopSmall;
						}
			
						case "desktopLarge": {
							imagProps.size = props.imageSize.desktopLarge;
						}
					}
				}
			} else {
				imagProps.size = "tiny";
			}
			return (
				<Image {...imagProps} src={props.imgURL} alt={props.imgAlt}/>
			)
		}
	}

	const getTitle = () => {
		if (props.title) {
			if (typeof props.title === "string") {
				return (
					<div className="font-bold mb-1">{props.title}</div>
				)
			} else {
				return props.title;
			}
		}
	}

	const getMetaData = () => {
		if (props.meta) {
			if (typeof props.meta === "string") {
				return (
					<div className="text-gray-600">{props.meta}</div>
				)
			} else {
				return props.meta;
			}
		}
	}

	const getContent = () => {
		if (props.content) {
			if (typeof props.content === "string") {
				return (
					<div>{props.content}</div>
				)
			} else {
				return props.content;
			}
		}
	}

	return (
		<div className="flex flex-row items-start mb-8" >
			{ getImage() }
			<div className="ml-8">
				{ getTitle() }
				{ getMetaData() }
				{ getContent() }
			</div>
		</div>
	)
}


export default FeatureItem;

