import React from "react"
import { TProductPageSection } from "../pages/products/ProductPageBase"
import { List } from "semantic-ui-react";

export type TCertifcation = {
	type: "ISO9001"
} | {
	type: "other"
}



export function productCertifcation(args: {certificates: TCertifcation[]}): TProductPageSection {
	const element = (<List bulleted>
			{ args.certificates.map( (certificate) => {
				switch (certificate.type) {
					 
					case "ISO9001":{
						return (<List.Item as="a" target="_blank" href={"/common/DENMYD_ISO9001.pdf"}>ISO9001</List.Item>)
					}
				}
			})}
		</List>
	);
	return {
		name: "Certifications",
		element,
	}
}