import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { Grid, Header, Container, Icon } from 'semantic-ui-react';
import { DisplaySizeContext } from '../ResponsiveLayout';
// import SendEmailRequest from "./SendEmailRequest"

function Contact() {
	
	const renderMarkers = (map:any, maps:any) => {
		let marker = new maps.Marker({
			position: {
				lat: -26.045585,
				lng: 28.088269
			},
			map,
			title: 'Hello World!'
		});
	}

	const displaySize = useContext(DisplaySizeContext);

	const mapHeight =() => {
		switch(displaySize) {
			case "mobile": {
				return "20rem"
			}

			case "desktopSmall": {
				return "25rem"
			}

			case "desktopLarge": {
				return "35rem"
			}
		}
	}

  return (
    <main className="mt-6">
		<div style={{ height: mapHeight(), width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyAF-fTRDKveCe5MU52JEIzgoYsv-7OzuJU" }}
				defaultCenter={{
					lat: -26.045585,
					lng: 28.088269
				}}
				defaultZoom={16}
				onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
			>
				
			</GoogleMapReact>
		</div>

		<div className="mt-12">
			<Header as="h3">Denmyd Medical Equipment (Pty) Ltd</Header>
			<Grid stackable columns={2}>
				<Grid.Row>
					<Grid.Column>
						<div className="flex flex-row content-start items-baseline">
							<Icon name="map marker alternate"/>
							<p className="text-lg leading-normal">
								11 Polo Crescent <br/>
								Woodmead Office Park <br/>
								Woodmead <br/>
								2191<br/>
								South Africa<br/>
							</p>
						</div>
					</Grid.Column>
					<Grid.Column>
						<p className="text-lg leading-loose">
							<Icon name="phone"/>  +27 (0)11 656 4559<br/>
							<Icon name="mail"/><a href="mailto:enquiries@denmyd.com">enquiries@denmyd.com</a><br/>
						</p>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
		{/* <SendEmailRequest/> */}
    </main>
  );
}

export default Contact;
