import React, { useState } from 'react';
import { Container, Header, Image, Item, Icon, Segment, Embed, Modal, Button, List } from 'semantic-ui-react';
import ProductPageBase from "./ProductPageBase";
import { productCertifcation } from '../../common/Cetification';

interface IModalVideo {
	title: string;
	videoId: string;
}

function FemoralHeadSlide() {

	return (
		<ProductPageBase
			name = "Femoral Head Slide"
			image = "/femoralHeadSlide/femoralHeadSlide.jpg"
			description = {{
				text: "Hip Inducer, used to aid in the insertion of the femoral head into the femoral socket.",
			}}
			sections ={ [
				{
					name: "Intended Applications",
					element: (
						<>
							<List bulleted>
								<List.Item>Minimally-invasive Hip Surgery</List.Item>
							</List>
						</>
					)
					
				},
				{ ... productCertifcation({
					certificates: [
						{
							type: "ISO9001"
						}
					]
				})},
			] }
					/>
	)
}

export default FemoralHeadSlide;