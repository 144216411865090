import React, { useState } from 'react';
import { Container, Header, Image, Item, Icon, Segment, Embed, Modal, Button, Card, List } from 'semantic-ui-react';
import ProductPageBase from "./ProductPageBase";
import FeatureItem from "./../../common/FeatureItem";
import { productCertifcation } from '../../common/Cetification';

interface IModalVideo {
	title: string;
	videoId: string;
}




function Visor() {

	function renderDesignFeatureItem (args: {
		img: string,
		heading: string,
		description: string
	}): JSX.Element {
		return (
			<FeatureItem 
				title={args.heading}
				imgURL={`/visor/icons/${args.img}`}
				imgAlt={`Synmyd Visor Feature - ${args.heading}`}
				content={args.description}
				imageSize={{
					type: "fixed",
					width: "mini"
				}}
			/>
			// <Item className="py-li-1">
			// 	<Item.Image size='mini' src={`/visor/icons/${args.img}`} />
			// 	<Item.Content>
			// 		<Item.Header as='a'>{args.heading}</Item.Header>
			// 		<Item.Description>
			// 			<p>{args.description}</p>
			// 		</Item.Description>
			// 	</Item.Content>
			// </Item>
		)
	}

	function renderVisorModel (args: {
		img: string;
		fullPageImage: string;
		heading: string;
		description: string;
	}){
		return (
			<Card>
				<Image src={`/visor/${args.img}`} wrapped ui={false} as='a' target="_blank" href={`/visor/${args.fullPageImage}`} alt={`Synmyd ${args.heading} Visor`}/>
				<Card.Content>
					<Card.Header>{args.heading}</Card.Header>
					<Card.Description>
						{args.description}
					</Card.Description>
				</Card.Content>
			</Card>
		)
	}

	function renderVisorModels() : JSX.Element {
		return (
			<div>
				<Card.Group>
					{ renderVisorModel({
						heading: "Regular",
						img: "visor_regular_small.png",
						description: "Front and oblique eye protection",
						fullPageImage: "visor_regular.png",
					})}

					{ renderVisorModel({
						heading: "Large",
						img: "visor_large_small.png",
						description: "Full face and oblique eye protection",
						fullPageImage: "visor_large.png",
					})}

					{ renderVisorModel({
						heading: "XL",
						img: "visor_xl_small.png",
						description: "Full face protection",
						fullPageImage: "visor_xl.png",
					})}
				</Card.Group>
			</div>
		);
	}

	return (
		<ProductPageBase
			name = "Synmyd Surgical Visor"
			image = "/visor/visor.jpeg"
			description = {{
				text: "Any application in which direct splash protection is required.",
			}}
			sections ={ [
				{
					name: "Intended Applications",
					element: (
						<>
							<List bulleted>
								<List.Item>Operation theatres</List.Item>
								<List.Item>Casualty areas</List.Item>
								<List.Item>Wards</List.Item>
								<List.Item>Emergency</List.Item>
								<List.Item>Covid-19 Protection</List.Item>
							</List>
						</>
					)
				},
				{
					name: "Design Features",
					element: (
						<>
							<Item.Group>
								{ renderDesignFeatureItem({
									heading: "Adjustable",
									img: "visor_adjustable_icon.png",
									description: "The visor can be adjusted forwards and backwards, allowing it working in combination with existing eyewear and masks such as glasses, surgical loops, goggles and face-masks"
								})}

								{ renderDesignFeatureItem({
									heading: "Optically Clear",
									img: "visor_optical_icon.png",
									description: "The visor shield is made of ultra-thin PET (Polyethylene Terephthalate). The frame of the visor anchors the shield as it bends into a flattered parabula, thus reducing reflection and distortion. The combinaation of these factors creates super-optical clarity"
								})}

								{ renderDesignFeatureItem({
									heading: "Prevents Fogging",
									img: "visor_prevent_fog.icon.png",
									description: "The visor is offset from the head creating an air-column, this not only allowing the visor to be adjusted, but allowing compatibility with existing eyewear and face-masks, simultaneously preventing fogging and shortness of breath"
								})}

								{ renderDesignFeatureItem({
									heading: "Speech and Hearing",
									img: "visor_hear_and_speak_icon.png",
									description: "The visor and shields are shaped so that there is minimal interference to speech and hearing"
								})}

								{ renderDesignFeatureItem({
									heading: "Comfortable",
									img: "visor_comfortable_icon.png",
									description: "The visor strap is made of a semi-rigid material, this allows the visor to rest, as opposed to feeling tight, on the head. Combined with the light-weight design and superior optics the visor is very comfortable and reduces headaches, sweating and eye-strain"
								})}

							</Item.Group>
						</>
					)
				},
				{
					name: "Sizes",
					element: renderVisorModels()
				},
				{ ... productCertifcation({
					certificates: [
						{
							type: "ISO9001"
						}
					]
				})},
			]}
			files = { [
				{
					type: "pdf",
					title: "Surgical Visor Brochure",
					url: "/visor/Synmyd_Visor_Brochure.pdf",
				},
				// {
				// 	type: "pdf",
				// 	title: "Brochure 2",
				// 	url: "/visor/Synmyd_Visor_Brochure_2.pdf",
				// },
				{
					type: "pdf",
					title: "Instructions",
					description: "How to use and adjust the visor",
					url: "/visor/SYNMYD_visor_instruction.pdf",
				},
				{
					type: "pdf",
					title: "Model Size comparison",
					description: "A document showing the different sizes of visors and their intended use",
					url: "/visor/SYNMYD_visor_size_compare.pdf",
				},
				{
					type: "youtube",
					title: "Instuctional Video",
					description: "Video showing the correct way to attach and adjust the Synmyd visor",
					videoId: "eG_9noX-6lQ",
				}
			] }

		/>
	)
}

export default Visor;