import React, { useState } from "react"
import { WIDTH_PHONE, WIDTH_DESKTOP_SMALL } from "../Variables";
import { Responsive } from "semantic-ui-react";
import { ResponsiveOnUpdateData } from "semantic-ui-react/dist/commonjs/addons/Responsive";

export type DisplaySizeType = "mobile" | "desktopSmall" | "desktopLarge"

export interface IResponsiveLayoutProps {
	mobile: () => JSX.Element;
	desktop: (size: "desktopSmall" | "desktopLarge" ) => JSX.Element;
}

export const DisplaySizeContext = React.createContext<DisplaySizeType>('mobile');

function ProductPageBase(props: IResponsiveLayoutProps) {

	const [currentDisplay, setCurrentDisplay] = useState<DisplaySizeType>("mobile");

	const handleWidthUpdate = (event: React.SyntheticEvent<HTMLElement>, data: ResponsiveOnUpdateData) => {
		const newWidth = data.width;
		let newDisplaySize:DisplaySizeType;
		if (newWidth <= WIDTH_PHONE) {
			newDisplaySize = "mobile";
		} else if (newWidth <= WIDTH_DESKTOP_SMALL) {
			newDisplaySize = "desktopSmall";
		} else {
			newDisplaySize = "desktopLarge";
		}
		if (newDisplaySize !== currentDisplay) {
			setCurrentDisplay(newDisplaySize);
		}
	};

	const renderLayout = (): JSX.Element => {
		switch (currentDisplay) {
			case "mobile": {
				return props.mobile();
			}

			case "desktopSmall": {
				return props.desktop("desktopSmall");
			}

			case "desktopLarge": {
				return props.desktop("desktopLarge");
			}
		}
	} 

	return (
		<DisplaySizeContext.Provider value={currentDisplay}>
			<Responsive onUpdate={handleWidthUpdate} fireOnMount>	
				{ renderLayout() }
			</Responsive>
		</DisplaySizeContext.Provider>
	)
}

export default ProductPageBase;


		
