import React, { useState, useEffect, useContext } from 'react';
// import { Menubar } from 'primereact/menubar';
// import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { Menu, Segment, Image, Responsive, Icon, Sidebar } from 'semantic-ui-react'
import { Link, useLocation } from "react-router-dom";
import ResponsiveLayout, { DisplaySizeType, DisplaySizeContext } from "./ResponsiveLayout"
import ProductPageLinks from './pages/products/ProductPageLinks';

function Navigation() {

	const [currentPath, setCurrentPath] = useState("");
	const [mobileSideMenuVisibility, setMobileSideMenuVisibility] = useState<boolean>(false);

	let location = useLocation();
  	useEffect(() => {
		setCurrentPath(location.pathname);
		if (mobileSideMenuVisibility) {
			setMobileSideMenuVisibility(false);
		}
	  }, [location]);

	const displaySize = useContext(DisplaySizeContext);

	  

	  const renderMenuItems = (factor: DisplaySizeType ): JSX.Element[] => {
		const menuItems = [];  
		if (factor === "mobile") {
			menuItems.push( (
				<Menu.Item >
					<Image src='/DENMYD_LOGO.png' size={"small"} alt="Denmyd Medical Equipment logo" rounded />
				</Menu.Item>
			));
		}
		menuItems.push( (
			<Menu.Item
				as={Link} to="/about"
				name='About Us'
				active={currentPath.startsWith('/about')}
			/>
		));
		if (factor !== "mobile") {
			menuItems.push( (
				<Menu.Item
				as={Link} to="/product/retractor"
				name='Products'
				active={currentPath.startsWith('/product')}
				/>
			));
		} else {
			menuItems.push( (
				<Menu.Item>
					<Menu.Header>Products</Menu.Header>
					<ProductPageLinks/>
				</Menu.Item>
			));
		}
		menuItems.push( (
			<Menu.Item
				as={Link} to="/agency"
				name='Agency'
				active={currentPath.startsWith('/agency')}
			/>
		));

		menuItems.push( (
			<Menu.Item
				as={Link} to="/contact"
				name='Contact'
				active={currentPath.startsWith('/contact')}
			/>
		));
		return menuItems;
	  }

	  const createDesktopMenu = (size : DisplaySizeType) => {
		const menuArgs:any = {};
		if (size === "desktopLarge") {
			menuArgs.size="massive"
		} 
		return (
			<Menu {...menuArgs} pointing>
				<Menu.Item>
					<Image src='/DENMYD_LOGO.png' size={size === "desktopLarge" ? "medium" : "small"} alt="Denmyd Medical Equipment logo" rounded />
				</Menu.Item>
				{ renderMenuItems(size) }
			</Menu>
		)
	}

	const createMobileMenu = ():JSX.Element => { 
		return (
			<>
				<Sidebar
					as={Menu}
					animation='overlay'
					onHide={ () => { setMobileSideMenuVisibility(false) } }
					vertical
					visible={mobileSideMenuVisibility}
				>
				{ renderMenuItems ("mobile") }
				</Sidebar>

				<Menu fixed="top" size="huge">
					<Menu.Item onClick={ () => { setMobileSideMenuVisibility(true) }}>
						<Icon name='sidebar' />
					</Menu.Item>
					<Menu.Item >
						<Image src='/DENMYD_LOGO.png' size={"small"} alt="Denmyd Medical Equipment logo" rounded />
					</Menu.Item>
				</Menu>
			</>
		)
	}

	const renderNavigation = () => {

		switch (displaySize) {
			
			case "mobile": {
				return createMobileMenu();
			}

			case "desktopSmall": {
				return createDesktopMenu("desktopSmall");
			}

			case "desktopLarge": {
				return createDesktopMenu("desktopLarge");
			}
		}
	}

  return renderNavigation();
}

export default Navigation;
