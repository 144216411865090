import React, { useState } from 'react';
import { Container, Header, Image, Item, Icon, Segment, Embed, Modal, Button } from 'semantic-ui-react';
import { Helmet } from "react-helmet";
import FeatureItem from '../../common/FeatureItem';

interface IModalVideo {
	title: string;
	videoId: string;
}

export type TProductPageSectionTextContent = {
	text: string
}

export type TProductPageSectionCustomContent = {
	element: JSX.Element
}

export type TProductPageSectionContent = TProductPageSectionTextContent | TProductPageSectionCustomContent

function isTProductPageSectionTextContent(content: TProductPageSectionContent): content is TProductPageSectionTextContent {
    return (content as TProductPageSectionTextContent).text !== undefined;
}

export type TProductPageSection = TProductPageSectionContent & {
	name: string
} 

export type TFileBase = {
	title: string;
	description?: string
}

export type TPDFile = TFileBase & {
	type: "pdf";
	url: string
}

export type TVideoFile = TFileBase & {
	type: "youtube";
	videoId: string
}

export type TFile = TPDFile | TVideoFile

export interface ProductPageBaseProps {
	name: string;
	image: string;
	description: TProductPageSectionTextContent;
	sections: TProductPageSection[];
	files?: TFile[];
}

function ProductPageBase(props: ProductPageBaseProps) {

	const [videoModal, setVideoModal] = useState< IModalVideo | undefined >();

	const renderModal = () => {
		if (videoModal) {
			return ( 
				<Modal open closeIcon onClose={ ()=> {
					setVideoModal(undefined);
				}}> 
				<Header icon='youtube' content={videoModal.title} />
					<Modal.Content>
					<Embed
						id={videoModal.videoId}
						// placeholder='/images/image-16by9.png'
						source='youtube'
					/>
					</Modal.Content>
				</Modal>
			)
		}
	}


 const renderSection = ( section: TProductPageSection ): JSX.Element => {
	 return (
		<Segment basic>
			<Header as="h2" dividing content={section.name}/>
			{ isTProductPageSectionTextContent(section)  
				? (<p>{section.text}</p>) 
				: section.element
			}
		</Segment>
	 )
 }

 const getFileIcon = (file : TFile): string => {
	 switch (file.type) {
		 case "pdf" : {
			return "/common/pdf.png";
		 }

		 case "youtube" : {
			return "/common/youtube.png";
		 }
	 }
 }

 const getTitle = (file : TFile): JSX.Element => {
	switch (file.type) {
		case "pdf" : {
		   return (
		   		<a className="font-bold mb-1" target="_blank" href={file.url}> {file.title}</a>
		   );
		}

		case "youtube" : {
		    return (
				<a className="text-xl font-bold mb-2" onClick={ ()=> {
					setVideoModal({
						title: file.title,
						videoId: file.videoId,
					});
				}}> {file.title}</a>
			);
		}
	}
}

 const renderFiles = ( ): JSX.Element | undefined => {
	 if (props.files) {
		const files = [];
		for (const file of props.files) {
			files.push ((

			<FeatureItem 
				title={ getTitle(file) }
				imgURL={ getFileIcon(file) }
				imgAlt={`${file.title} ${file.type} file`}
				meta={file.description}
				imageSize={{
					type: "fixed",
					width: "mini"
				}}
			/>


				// <Item >
				// 	<Item.Image size='mini' src={ getFileIcon(file) } />
				// 	<Item.Content verticalAlign="middle">
				// 		{ getTitle(file) }
				// 		{ file.description ? ( <Item.Meta>{file.description}</Item.Meta> ) : undefined }
				// 	</Item.Content>
				// </Item> 
			))
		}
		return renderSection( {
			name: "Files",
			element: (
				<>
					{ files } 
				</>

				// <Item.Group>
					
				// </Item.Group>
			),
		})
	 } else {
		 return undefined;
	 }
}

  return (
    <div className="mt-6">
		<Helmet>
			<title>{props.name}</title>
			<meta name="description" content={props.description.text}/>
		</Helmet>
		{ renderModal() }
		<Container>
			<Segment basic>
				<Header as="h1" content={props.name}/>
				<Image src={props.image} size="huge" alt={`${props.name}`} centered bordered/>
			</Segment>

			{ renderSection( {
				...props.description,
				name: "Description",
			})}

			{ props.sections.map( (section) => renderSection(section) )}

			{ renderFiles() }

		</Container>
	</div>
  );
}

export default ProductPageBase;