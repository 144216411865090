import React, { useContext } from 'react';
import { Container, Header, Item, List, Image } from 'semantic-ui-react';
import { DisplaySizeContext } from '../ResponsiveLayout';

function Agency() {

	const displaySize = useContext(DisplaySizeContext);

	function renderAgency (args: {
		img: string,
		heading: string,
		description: string | JSX.Element,
		website: string,
	}): JSX.Element {
		return (
			<div className="flex flex-row items-start mb-16" >
				<Image size={displaySize === "mobile" ? 'mini' : 'tiny'} src={`/agency/${args.img}`} alt={`${args.heading} Logo`}/>
				<div className="ml-10">
					<div className="text-2xl font-bold">{args.heading}</div>
					<a className="text-blue-600 " href={"http://" + args.website} target="_blank">{args.website}</a>
					<div>{args.description}</div>
				</div>
			</div>
			// <Item className="py-li-3"> 
			// 	<Item.Image size={displaySize === "mobile" ? 'mini' : 'tiny'} src={`/agency/${args.img}`} style={{width: "32px !important"}}/>
			// 	<Item.Content>
			// 		<Item.Header>{args.heading}</Item.Header>
			// 		<Item.Meta>
			// 			<a href={"http://" + args.website} target="_blank">{args.website}</a>
			// 		</Item.Meta>
			// 		<Item.Description>
			// 			<p>{args.description}</p>
			// 		</Item.Description>
			// 	</Item.Content>
			// </Item>
		)
	}

  return (
		<main className="mt-6">
			<Container text>
				<p>
					Denmyd Medical Equipment is pround agent for the following companies:
				</p>
				<Item.Group unstackable>
					{ renderAgency({
						heading: "Komet Medical",
						img: "komet_logo.png",
						website: "www.kometmedical.de/",
						description: "Komet Medical has been developing and manufacturing motor-powered precision instruments for use in surgical drive systems since 1988. The comprehensive range includes surgical saw blades, rasps, burs, cutters, craniotomies and twist drills as well as diamond abrasives",
					})}

					{ renderAgency({
						heading: "Ceraver",
						img: "ceraver_logo.jpeg",
						website: "www.ceraver.com",
						description: (
							<>
								<div>
									Established in the Paris region, CERAVER has always strived to maintain all of its R&D and manufacturing in France. Thus, design, industrial know-how, production and certification are 100% carried out on French territory, thus respecting one of the highest levels of regulatory requirements in Europe.
								</div>
								<br/>
								<p>The activity is divided into 4 areas</p>
								<List bulleted>
									<List.Item>Hip (prostheses in titanium alloy, cemented or non-cemented with alumina-alumina, alumina-PE, metal-PE friction couple)</List.Item>
									<List.Item>Knee (unicompartmental, femoro-patellar, tricompartmental prostheses with movable or fixed platform with or without preservation of the cruciate ligaments, revision)</List.Item>
									<List.Item>Shoulder (prostheses for fracture, anatomical, inverted)</List.Item>
									<List.Item>Biomaterials (cements, bone substitutes, antibacterial coating)</List.Item>
								</List>
							</>
						)
					})}

				</Item.Group>
			</Container>
		</main>
		
  );
}

export default Agency;
