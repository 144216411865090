import React, { useState } from 'react';
import { Container, Header, Image, Item, Icon, Segment, Embed, Modal, Button, List } from 'semantic-ui-react';
import ProductPageBase from "./ProductPageBase";
import { productCertifcation } from '../../common/Cetification';

interface IModalVideo {
	title: string;
	videoId: string;
}

function SplashShield() {

	return (
		<ProductPageBase
			name = "Splash Shield"
			image = "/splashShield/splashShield.jpg"
			description = {{
				text: "Splash Shield to prevent blood-splatter from impaction of femoral component in Minimally-invasive Hip Surgery",
			}}
			sections ={ [
				{
					name: "Intended Applications",
					element: (
						<>
							<List bulleted>
								<List.Item>Minimally-invasive Hip Surgery</List.Item>
								<List.Item>Supports anterior-lateral and posterior approaches</List.Item>
							</List>
						</>
					)
				},
				{ ... productCertifcation({
					certificates: [
						{
							type: "ISO9001"
						}
					]
				})},
			] }
					/>
	)
}

export default SplashShield;