import React, { useState, useEffect } from 'react';
import { Container, Header, Grid, Menu, Segment, GridRow } from 'semantic-ui-react';
import { useParams, Link, useLocation } from "react-router-dom";
import Visor from "./products/Visor";
import HipTissueElevator from "./products/HipTissueElevator";
import FemoralHeadSlide from "./products/FemoralHeadSlide";
import SplashShield from "./products/SplashShield";
import { render } from '@testing-library/react';
import ResponsiveLayout, { DisplaySizeType } from "./../ResponsiveLayout";
import ProductPageLinks from './products/ProductPageLinks';


function Product() {

	const { productId : selectedProductId } = useParams();

	const renderContent = (): JSX.Element => {
		switch ( selectedProductId ){

			case "visor": {
				return <Visor/>
			} break;

			case "retractor": {
				return <HipTissueElevator/>
			} break;

			case "femoralHeadSlide": {
				return <FemoralHeadSlide/>
			} break;

			case "splashShield": {
				return <SplashShield/>
			} break;

			default: {
				return (
					<pre>
						{ JSON.stringify(selectedProductId ,null, "\t") }
					</pre>
				)
			}
		}
	}

	
	const renderMobile = (): JSX.Element => {
		return renderContent();
	}

	const renderDesktop = (size: DisplaySizeType): JSX.Element => {
		return (
			<Grid>
				<Grid.Row columns={2}>
					<Grid.Column width={3}>
						<Menu pointing secondary vertical color="blue">
							<ProductPageLinks/>
						</Menu>
					</Grid.Column>

					<Grid.Column width={13}>
						{ renderContent() }
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}

	return (
		<ResponsiveLayout 
			mobile={renderMobile}
			desktop={renderDesktop}
		/>
	)
}

export default Product;
