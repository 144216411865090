import React, { useState } from 'react';
import { Container, Header, Image, Item, Icon, Segment, Embed, Modal, Button, List } from 'semantic-ui-react';
import ProductPageBase from "./ProductPageBase";
import { productCertifcation } from '../../common/Cetification';

interface IModalVideo {
	title: string;
	videoId: string;
}

function HipTissueElevator() {

	return (
		<ProductPageBase
			name = "Hip Tissue Retractor"
			image = "/hipTissueElevator/hipTissueElevator.jpg"
			description = {{
				text: "Retractor used in Minimally-invasive Hip Surgery. Uses linear-retraction to minimize nerve and tissue damage.",
			}}
			sections ={ [
				{
					name: "Intended Applications",
					element: (
						<>
							<List bulleted>
								<List.Item>Minimally-invasive Hip Surgery</List.Item>
								<List.Item>Supports anterior-lateral and posterior approaches</List.Item>
							</List>
						</>
					)
					
				},
				{ ... productCertifcation({
					certificates: [
						{
							type: "ISO9001"
						}
					]
				})},
			] }
					/>
	)
}

export default HipTissueElevator;