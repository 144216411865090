import React from 'react';
import { Container, Header, Grid, Image } from 'semantic-ui-react';

export const aboutText: string =  "Denmyd Medical Equipment was established in 2011 as an innovator in the design of instruments for the medical industry. Since its inception it has been involved in the healthcare industry. This core vision continues as the company continues to develop and manufacture. Along with distribution of its own products, Denmyd imports products sourced from carefully selected manufacturers of global repute."

function About() {
  return (
    <main className="mt-6">
		<Container>
			<Header as="h1">About</Header>
			<Grid stackable>
				<Grid.Row>
				<Grid.Column width={6}>
					<p>
						{aboutText}
					</p>
				</Grid.Column>
				<Grid.Column width={10}>
					<Image src='/building.jpg' alt="Denmyd Medical Equipment - Johannesburg Office"/>
				</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
		</main>
  );
}

export default About;
