import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";
import { Menu } from 'semantic-ui-react';

export const productsLinks = {
	"retractor": "Hip Tissue Retractor",
	"femoralHeadSlide": "Femoral Head Side",
	"splashShield": "Splash Shield",
	"visor": "Synmyd Visor",
}

function ProductPageLinks() {

	const { productId : selectedProductId } = useParams();

	const createTab = (productId: string, product: string): JSX.Element => {
		return (
			<Menu.Item
				as={Link} to={`/product/${productId}`}
				name={product}
				active={selectedProductId === productId}
			/>
		)
	}

	const renderLinks = (): JSX.Element[] => {
		const links : JSX.Element[] = [];
		for (const [url, page] of Object.entries(productsLinks)) {
			links.push( (
				createTab(url, page )
			))
		}
		return links;
	}

	return 	<>
		{ renderLinks() } 
	</>;
}

export default ProductPageLinks;
